<template>
    <v-container>
        <v-row class="text-center" align="start">
            <v-col cols="12">
                <a href="./assets/resume.pdf" download="CollinKerstenResume">
                    <h1>Download Here!</h1>
                </a>
                <embed src="./assets/resume.pdf" type="application/pdf" height="700" width="500">
            </v-col>
        </v-row>
    </v-container>
</template>
